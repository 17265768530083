import {
  getDataByUrl,
  postDataByUrl,
  deleteDataByUrl,
  putDataByUrl
} from 'requests';



export const getToDos = () => getDataByUrl(`/todos/`);

export const getToDosBYName = (searchName: any) =>
  getDataByUrl(`/todos/?q=${searchName}`);

export const getToDosFiltered = (periodicity: any) =>
  getDataByUrl(`/todos/`, periodicity);

export const getToDoById = (id: any) => getDataByUrl(`/todos/${id}/`);

export const CreateToDos = (toDoFeld: any) =>
  postDataByUrl(`/todos/`, toDoFeld);

export const UpdateToDO = (id: any, toDoFeld: any) =>
  putDataByUrl(`/todos/${id}/`, toDoFeld);

export const DeliteToDo = (id: any) => deleteDataByUrl(`/todos/${id}/`);

