import {
  getDataByUrl,
  postDataByUrl,
  deleteDataByUrl,
  putDataByUrl
} from 'requests';

export const GetEvents = (
  q?: string,
  year_month?: string,
  periodicity?: string,
  owner?: string,
  limit?: string
) => {
  const params = [];

  if (q) params.push(`q=${q}`);
  if (year_month) params.push(`year_month=${year_month}`);
  if (periodicity) params.push(`periodicity=${periodicity}`);
  if (owner) params.push(`owner=${owner}`);
  if (limit) params.push(`limit=${limit}`);

  const query = params.length > 0 ? `?${params.join('&')}` : '';

  return getDataByUrl(`/calendars/events/${query}`);
};

export const CreateEvent = (customerFelds: any) =>
  postDataByUrl(`/calendars/events/`, customerFelds);

export const UpdateEventData = (id: string, data: any) =>
  putDataByUrl(`/calendars/events/${id}/`, data);

export const DeleteEvent = (id: string) =>
  deleteDataByUrl(`/calendars/events/${id}/`);

export const GetEventById = (id: string) =>
  getDataByUrl(`/calendars/events/${id}/`);
