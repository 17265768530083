import { getDataByUrl, ID, postDataByUrl } from 'requests';

export const getSubscription = () => getDataByUrl(`/subscription/`);
export const getPlans = () => getDataByUrl(`/subscription/plans/`);
export const getInvoices = () => getDataByUrl(`/subscription/invoices/`);

export const subscribeOnPlan = (planID: ID) =>
  postDataByUrl(`/subscription/create/`, {
    planID
  });

export const subscribeCheckout = (planID: ID) =>
  postDataByUrl(`/subscription/checkout/session/`, {
    planID
  });

export const getSubscriptionPortal = () =>
  postDataByUrl(`/subscription/customer/portal/`);
