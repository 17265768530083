import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
// hooks
import { useSelector } from 'react-redux';
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_HOME } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';
import { RootState } from '../redux/store';

// ----------------------------------------------------------------------

type GuestProtectProps = {
  children: ReactNode;
};

export default function GuestProtect({ children }: GuestProtectProps) {
  const { isLoading, isAuthenticated } = useAuth();
  const user = useSelector((state: RootState) => state.authJwt.user);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    // switch (user?.role) {
    //   case 'recruiter':
    //     return <Redirect to={PATH_DASHBOARD.account.showcase} />;
    //   case 'admin':
    //     return <Redirect to={PATH_DASHBOARD.account.showcase} />;
    //   case 'candidate':
    //     return (
    //       <Redirect
    //         to={PATH_DASHBOARD.candidates.getCandidateProfileUrl('me')}
    //       />
    //     );
    //   case 'employee':
    //     return (
    //       <Redirect
    //         to={PATH_DASHBOARD.candidates.getCandidateProfileUrl('me')}
    //       />
    //     );
    //   default:
    //     return <Redirect to={PATH_HOME.home} />;
    // }

    return <Redirect to={PATH_HOME.home} />;
  }

  return <>{children}</>;
}
