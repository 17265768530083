import { getDataByUrl, postDataByUrl, putDataByUrl } from 'requests';

const base_url = '/vonq/products';

export const getVonqProducts = (params?: any) =>
  getDataByUrl(`${base_url}/search/`, params);

export const getVonqLocations = (location: string) =>
  getDataByUrl(`${base_url}/locations/`, { text: location });

export const getVonqJobTitles = async (job_title: string) => {
  const res = await getDataByUrl(`${base_url}/job-titles/`, {
    text: job_title
  });
  return res?.results;
};

export const getVonqJobFunctions = () =>
  getDataByUrl(`${base_url}/job-functions/`);

export const getVonqIndustries = (param: string) =>
  getDataByUrl(`${base_url}/industries/`, { text: param });

export const getVonqEducationLevels = () =>
  getDataByUrl(`/vonq/education-levels/`);

export const getVonqSeniority = () => getDataByUrl(`/vonq/seniority/`);

export const orderCampaigns = (data: any) =>
  postDataByUrl(`/vonq/campaigns/`, data);

export const getCampaigns = (params?: any) =>
  getDataByUrl(`/vonq/campaigns/`, params);

export const getCampaignsDetails = (id: number | string) =>
  getDataByUrl(`/vonq/campaigns/${id}/details/`);

export const getCampaignsStatus = (id: number | string) =>
  getDataByUrl(`/vonq/campaigns/${id}/status/`);

export const getOneCampaign = (id: number | string) =>
  getDataByUrl(`/vonq/campaigns/${id}/`);

export const cancelCampaign = (id: number | string) =>
  putDataByUrl(`/vonq/campaigns/${id}/cancel/`, {});
