import {
  deleteDataByUrl,
  getDataByUrl,
  postDataByUrl,
  updateDataByUrl
} from './index';

export const getTemplates = (params: any) =>
  getDataByUrl(`/templates/`, params);
export const postTemplate = async (data: any) =>
  postDataByUrl(`/templates/`, data);
export const postTemplateCount = async (id: number) =>
  postDataByUrl(`/templates/${id}/add_count/`);
export const deleteTemplate = async (id: any) =>
  deleteDataByUrl(`/templates/${id}/`);
export const patchTemplate = (id: any, data: any) =>
  updateDataByUrl(`/templates/${id}/`, data);
