import {
  getDataByUrl,
  postDataByUrl,
  updateDataByUrl,
  deleteDataByUrl,
  ID
} from 'requests';

export const getJobPosts = (params: any) => getDataByUrl('/vacancy/', params);

export const getJobPost = (id: ID) => getDataByUrl(`/vacancy/${id}/`);

export const postNewJobPost =  (data: any) =>  postDataByUrl(`/vacancy/`, data, {}, true);

export const updateJobPost = (id: ID, data: any) =>
  updateDataByUrl(`/vacancy/${id}/`, data);

export const deleteJobPost = (id: ID) => deleteDataByUrl(`/vacancy/${id}/`);

export const getApplicants = (params: any) =>
  getDataByUrl(`/vacancy/applications/`, params);

export const applyJobPost = (data: any) =>
  postDataByUrl(`/vacancy/applications/`, data);

export const uploadJobFile = (data: any) =>
  postDataByUrl('/openai/upload_document/', data);
