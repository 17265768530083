import { deleteDataByUrl, getDataByUrl, postDataByUrl } from './index';

export const postRooms = async (candidate: any) =>
  postDataByUrl(`/messenger/rooms/`, candidate);
export const getAllRooms = async () => getDataByUrl(`/messenger/rooms/`);
export const getPrivateRooms = async (candidate: any) =>
  getDataByUrl(`/messenger/rooms/?candidate=${candidate}`);
export const getMessageRoom = async (room: any) =>
  getDataByUrl(`/messenger/messages/?room=${room}`);
export const getAllNotifications = async () =>
  getDataByUrl(`/notifications/all/`);
export const postMessage = async (data: any) =>
  postDataByUrl(`/messenger/messages/`, data);
export const deleteRoom = async (room: number) =>
  deleteDataByUrl(`/messenger/rooms/${room}/`);
export const getNotifications = async () =>
  getDataByUrl(`/notifications/unread/`);
export const getUnreadCount = async () =>
  getDataByUrl(`/notifications/api/unread_count/`);
export const getAllReadNotifications = async () =>
  getDataByUrl(`/notifications/mark-all-as-read/`);
export const getAsReadNotifications = async (id: any) =>
  getDataByUrl(`/notifications/mark-as-read/${id}/`);
export const getTemplates = async (name: string) =>
  getDataByUrl(`/templates/?limit=10&q=${name ?? ''}`);
export const postTemplates = async (data: any) =>
  postDataByUrl(`/templates/`, data);
export const postTemplateCount = async (id: number) =>
  postDataByUrl(`/templates/${id}/add_count/`);
export const getColleagues = async (name: string) =>
  getDataByUrl(`/account/?role=recruiters&q=${name ?? ''}`);
export const getPrivateMessages = async (userId: number, colleagueId: number) =>
  getDataByUrl(
    `/messenger/private_messages/?user1=${userId}&user2=${colleagueId}`
  );
export const postPrivateMessage = async (data: any) =>
  postDataByUrl(`/messenger/private_messages/`, data);

export const deletePrivate = async (userId: number, colleagueId: number) =>
  deleteDataByUrl(
    `/messenger/private_messages/delete_conversation/?user1=${userId}&user2=${colleagueId}`
  );
export const getCandidates = (name: string) =>
  getDataByUrl(`/candidate/?limit=7&q=${name ?? ''}`);